::selection {
  background: #329cb9;
  color: #fff;
  text-shadow: none;
}

::-webkit-selection {
  background: #329cb9;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #329cb9;
  color: #fff;
  text-shadow: none;
}
